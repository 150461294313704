import React from 'react';

import Container from '../components/container.js';

import '../styles/global.scss';

import TwitterIcon from '../images/twitter-icon.png';
import GithubIcon from '../images/github-icon.png';
import LinkedinIcon from '../images/linkedin-icon.png';

export default () => (
    <Container>
        <div className="row">
            <div className="column column-center margin-bottom">
                <span className="header-name">Brett Barber</span>
            </div>
        </div>
        <div className="row">
            <div className="column column-center margin-bottom">
                <span className="header-titles">Developer - Designer - Entrepreneur</span>
            </div>
        </div>
        <div className="row">
            <div className="column column-center">
                <button type="button" className="header-start-button">
                    Let's go!
                </button>
            </div>
        </div>
    </Container>  
);